export default {
  "chainId": "11155111",
  "genesisHash": "0x25a5cc106eea7138acab33231d7160d69cb777ee0c2c553fcddf5138993e6dd9",
  "contracts": {
    "Gems": {
      "abi": [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentTime",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadline",
              "type": "uint256"
            }
          ],
          "name": "DeadlineOver",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "addr",
              "type": "address"
            }
          ],
          "name": "InvalidAddress",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "provided",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "InvalidMsgValue",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidSignature",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "provided",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "InvalidTotalAmount",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentAllowance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "NotAuthorizedAllowance",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "NotEnoughTokens",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "DOMAIN_SEPARATOR",
          "outputs": [
            {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            }
          ],
          "name": "allowance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "approveAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "decimals",
          "outputs": [
            {
              "internalType": "uint8",
              "name": "",
              "type": "uint8"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address payable[]",
              "name": "tos",
              "type": "address[]"
            },
            {
              "internalType": "uint256",
              "name": "totalAmount",
              "type": "uint256"
            }
          ],
          "name": "distributeAlongWithETH",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "eip712Domain",
          "outputs": [
            {
              "internalType": "bytes1",
              "name": "fields",
              "type": "bytes1"
            },
            {
              "internalType": "string",
              "name": "name",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "version",
              "type": "string"
            },
            {
              "internalType": "uint256",
              "name": "chainID",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "verifyingContract",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "salt",
              "type": "bytes32"
            },
            {
              "internalType": "uint256[]",
              "name": "extensions",
              "type": "uint256[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "name": "nonces",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadline",
              "type": "uint256"
            },
            {
              "internalType": "uint8",
              "name": "v",
              "type": "uint8"
            },
            {
              "internalType": "bytes32",
              "name": "r",
              "type": "bytes32"
            },
            {
              "internalType": "bytes32",
              "name": "s",
              "type": "bytes32"
            }
          ],
          "name": "permit",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "totalSupply",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transfer",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address payable",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transferAlongWithETH",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferFromAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "forAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferOnBehalfAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        }
      ],
      "address": "0x38e9f1146e8ee787686292c39df1e388c7e42bf5",
      "startBlock": 4794074
    },
    "Gems_Implementation": {
      "abi": [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentTime",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadline",
              "type": "uint256"
            }
          ],
          "name": "DeadlineOver",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "addr",
              "type": "address"
            }
          ],
          "name": "InvalidAddress",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "provided",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "InvalidMsgValue",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidSignature",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "provided",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "InvalidTotalAmount",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentAllowance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "NotAuthorizedAllowance",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "NotEnoughTokens",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "DOMAIN_SEPARATOR",
          "outputs": [
            {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            }
          ],
          "name": "allowance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "approveAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "decimals",
          "outputs": [
            {
              "internalType": "uint8",
              "name": "",
              "type": "uint8"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address payable[]",
              "name": "tos",
              "type": "address[]"
            },
            {
              "internalType": "uint256",
              "name": "totalAmount",
              "type": "uint256"
            }
          ],
          "name": "distributeAlongWithETH",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "eip712Domain",
          "outputs": [
            {
              "internalType": "bytes1",
              "name": "fields",
              "type": "bytes1"
            },
            {
              "internalType": "string",
              "name": "name",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "version",
              "type": "string"
            },
            {
              "internalType": "uint256",
              "name": "chainID",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "verifyingContract",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "salt",
              "type": "bytes32"
            },
            {
              "internalType": "uint256[]",
              "name": "extensions",
              "type": "uint256[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "name": "nonces",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadline",
              "type": "uint256"
            },
            {
              "internalType": "uint8",
              "name": "v",
              "type": "uint8"
            },
            {
              "internalType": "bytes32",
              "name": "r",
              "type": "bytes32"
            },
            {
              "internalType": "bytes32",
              "name": "s",
              "type": "bytes32"
            }
          ],
          "name": "permit",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "totalSupply",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transfer",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address payable",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transferAlongWithETH",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferFromAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "forAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferOnBehalfAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        }
      ],
      "address": "0x4413cd07c0c5e992f3c62e5a3a85aaaf02040c38",
      "startBlock": 4794073
    },
    "Gems_Proxy": {
      "abi": [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "implementationAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "ownerAddress",
              "type": "address"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "stateMutability": "payable",
          "type": "constructor"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "previousOwner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "OwnershipTransferred",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "previousImplementation",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newImplementation",
              "type": "address"
            }
          ],
          "name": "ProxyImplementationUpdated",
          "type": "event"
        },
        {
          "stateMutability": "payable",
          "type": "fallback"
        },
        {
          "inputs": [],
          "name": "owner",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes4",
              "name": "id",
              "type": "bytes4"
            }
          ],
          "name": "supportsInterface",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "transferOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newImplementation",
              "type": "address"
            }
          ],
          "name": "upgradeTo",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newImplementation",
              "type": "address"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "upgradeToAndCall",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "stateMutability": "payable",
          "type": "receive"
        }
      ],
      "address": "0x38e9f1146e8ee787686292c39df1e388c7e42bf5",
      "startBlock": 4794074
    },
    "Stratagems": {
      "abi": [
        {
          "inputs": [],
          "name": "CanStillReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "CommitmentHashNotMatching",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "GameNotStarted",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InCommitmentPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InRevealPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidEpoch",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidFurtherMoves",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NothingToReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "PreviousCommitmentNotRevealed",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "inReserve",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "ReserveTooLow",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            }
          ],
          "name": "CommitmentCancelled",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentMade",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": true,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newReserveAmount",
              "type": "uint256"
            }
          ],
          "name": "CommitmentRevealed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountBurnt",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentVoid",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "oldColor",
              "type": "uint8"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "newColor",
              "type": "uint8"
            }
          ],
          "name": "MoveProcessed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountDeposited",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveDeposited",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountWithdrawn",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveWithdrawn",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            }
          ],
          "name": "getCell",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.FullCell",
              "name": "",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256[]",
              "name": "ids",
              "type": "uint256[]"
            }
          ],
          "name": "getCells",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.FullCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "getCommitment",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "bytes24",
                  "name": "hash",
                  "type": "bytes24"
                },
                {
                  "internalType": "uint24",
                  "name": "epoch",
                  "type": "uint24"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Commitment",
              "name": "commitment",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "getConfig",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "contract IERC20WithIERC2612",
                  "name": "tokens",
                  "type": "address"
                },
                {
                  "internalType": "address payable",
                  "name": "burnAddress",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "startTime",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "commitPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "revealPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "maxLife",
                  "type": "uint8"
                },
                {
                  "internalType": "uint256",
                  "name": "numTokensPerGems",
                  "type": "uint256"
                },
                {
                  "internalType": "contract ITime",
                  "name": "time",
                  "type": "address"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Config",
              "name": "config",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "getTokensInReserve",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "secret",
              "type": "bytes32"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "acknowledgeMissedReveal",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "acknowledgeMissedRevealByBurningAllReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokensAmountToAdd",
              "type": "uint256"
            },
            {
              "components": [
                {
                  "internalType": "uint256",
                  "name": "value",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "deadline",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "v",
                  "type": "uint8"
                },
                {
                  "internalType": "bytes32",
                  "name": "r",
                  "type": "bytes32"
                },
                {
                  "internalType": "bytes32",
                  "name": "s",
                  "type": "bytes32"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Permit",
              "name": "permit",
              "type": "tuple"
            }
          ],
          "name": "addToReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "cancelCommitment",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "makeCommitment",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "internalType": "uint256",
              "name": "tokensAmountToAdd",
              "type": "uint256"
            },
            {
              "components": [
                {
                  "internalType": "uint256",
                  "name": "value",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "deadline",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "v",
                  "type": "uint8"
                },
                {
                  "internalType": "bytes32",
                  "name": "r",
                  "type": "bytes32"
                },
                {
                  "internalType": "bytes32",
                  "name": "s",
                  "type": "bytes32"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Permit",
              "name": "permit",
              "type": "tuple"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "makeCommitmentWithExtraReserve",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            }
          ],
          "name": "poke",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint64[]",
              "name": "positions",
              "type": "uint64[]"
            }
          ],
          "name": "pokeMultiple",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "secret",
              "type": "bytes32"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "internalType": "bool",
              "name": "useReserve",
              "type": "bool"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "reveal",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "withdrawFromReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "addr",
              "type": "address"
            }
          ],
          "name": "InvalidAddress",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "NonExistentToken",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NonceOverflow",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NotAuthorized",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "provided",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "currentOwner",
              "type": "address"
            }
          ],
          "name": "NotOwner",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "TransferRejected",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "approved",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "bool",
              "name": "approved",
              "type": "bool"
            }
          ],
          "name": "ApprovalForAll",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "getApproved",
          "outputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            }
          ],
          "name": "isApprovedForAll",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256[]",
              "name": "tokenIDs",
              "type": "uint256[]"
            }
          ],
          "name": "ownerAndLastTransferBlockNumberList",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "lastTransferBlockNumber",
                  "type": "uint256"
                }
              ],
              "internalType": "struct IERC721WithBlocknumber.OwnerData[]",
              "name": "ownersData",
              "type": "tuple[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "ownerAndLastTransferBlockNumberOf",
          "outputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "blockNumber",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "ownerOf",
          "outputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "safeTransferFrom",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "internalType": "bool",
              "name": "approved",
              "type": "bool"
            }
          ],
          "name": "setApprovalForAll",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes4",
              "name": "interfaceID",
              "type": "bytes4"
            }
          ],
          "name": "supportsInterface",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "tokenURI",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "InvalidCellOverwrite",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "life",
              "type": "uint256"
            },
            {
              "internalType": "int32",
              "name": "x",
              "type": "int32"
            },
            {
              "internalType": "int32",
              "name": "y",
              "type": "int32"
            }
          ],
          "name": "InvalidLifeConfiguration",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct IStratagemsDebug.DebugCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "ForceCells",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct IStratagemsDebug.SimpleCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "ForceSimpleCells",
          "type": "event"
        },
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                }
              ],
              "internalType": "struct IStratagemsDebug.DebugCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "forceCells",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            }
          ],
          "name": "forceMoves",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                }
              ],
              "internalType": "struct IStratagemsDebug.SimpleCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "forceSimpleCells",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            }
          ],
          "name": "getRawCell",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Cell",
              "name": "",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ],
      "address": "0x82404f6cf06afa60e472a611224c016469f797ec",
      "linkedData": {
        "tokens": "0xa3573742b86d1580b710653efcf03a405fe80f7d",
        "numTokensPerGems": "1000000000000000000n",
        "burnAddress": "0xDEADDEADDEADDEADDEADDEADDEADDEADDEADDEAD",
        "startTime": 0,
        "commitPhaseDuration": "82800n",
        "revealPhaseDuration": "3600n",
        "maxLife": 7,
        "time": "0x52092c12759ac66d997d402c07d5449b3f2136f8",
        "currency": {
          "symbol": "TOKEN",
          "name": "Tokens",
          "decimals": "18n"
        },
        "admin": "0x61c461ecc993aadeb7e4b47e96d1b8cc37314b20"
      },
      "startBlock": 4794079
    },
    "Stratagems_Implementation": {
      "abi": [
        {
          "inputs": [],
          "name": "CanStillReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "CommitmentHashNotMatching",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "GameNotStarted",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InCommitmentPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InRevealPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidEpoch",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidFurtherMoves",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NothingToReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "PreviousCommitmentNotRevealed",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "inReserve",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "ReserveTooLow",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            }
          ],
          "name": "CommitmentCancelled",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentMade",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": true,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newReserveAmount",
              "type": "uint256"
            }
          ],
          "name": "CommitmentRevealed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountBurnt",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentVoid",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "oldColor",
              "type": "uint8"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "newColor",
              "type": "uint8"
            }
          ],
          "name": "MoveProcessed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountDeposited",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveDeposited",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountWithdrawn",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveWithdrawn",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            }
          ],
          "name": "getCell",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.FullCell",
              "name": "",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256[]",
              "name": "ids",
              "type": "uint256[]"
            }
          ],
          "name": "getCells",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.FullCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "getCommitment",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "bytes24",
                  "name": "hash",
                  "type": "bytes24"
                },
                {
                  "internalType": "uint24",
                  "name": "epoch",
                  "type": "uint24"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Commitment",
              "name": "commitment",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "getConfig",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "contract IERC20WithIERC2612",
                  "name": "tokens",
                  "type": "address"
                },
                {
                  "internalType": "address payable",
                  "name": "burnAddress",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "startTime",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "commitPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "revealPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "maxLife",
                  "type": "uint8"
                },
                {
                  "internalType": "uint256",
                  "name": "numTokensPerGems",
                  "type": "uint256"
                },
                {
                  "internalType": "contract ITime",
                  "name": "time",
                  "type": "address"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Config",
              "name": "config",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "getTokensInReserve",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "secret",
              "type": "bytes32"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "acknowledgeMissedReveal",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "acknowledgeMissedRevealByBurningAllReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokensAmountToAdd",
              "type": "uint256"
            },
            {
              "components": [
                {
                  "internalType": "uint256",
                  "name": "value",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "deadline",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "v",
                  "type": "uint8"
                },
                {
                  "internalType": "bytes32",
                  "name": "r",
                  "type": "bytes32"
                },
                {
                  "internalType": "bytes32",
                  "name": "s",
                  "type": "bytes32"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Permit",
              "name": "permit",
              "type": "tuple"
            }
          ],
          "name": "addToReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "cancelCommitment",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "makeCommitment",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "internalType": "uint256",
              "name": "tokensAmountToAdd",
              "type": "uint256"
            },
            {
              "components": [
                {
                  "internalType": "uint256",
                  "name": "value",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "deadline",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "v",
                  "type": "uint8"
                },
                {
                  "internalType": "bytes32",
                  "name": "r",
                  "type": "bytes32"
                },
                {
                  "internalType": "bytes32",
                  "name": "s",
                  "type": "bytes32"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Permit",
              "name": "permit",
              "type": "tuple"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "makeCommitmentWithExtraReserve",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            }
          ],
          "name": "poke",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint64[]",
              "name": "positions",
              "type": "uint64[]"
            }
          ],
          "name": "pokeMultiple",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "secret",
              "type": "bytes32"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "internalType": "bool",
              "name": "useReserve",
              "type": "bool"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "reveal",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "withdrawFromReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "addr",
              "type": "address"
            }
          ],
          "name": "InvalidAddress",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "NonExistentToken",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NonceOverflow",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NotAuthorized",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "provided",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "currentOwner",
              "type": "address"
            }
          ],
          "name": "NotOwner",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "TransferRejected",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "approved",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "bool",
              "name": "approved",
              "type": "bool"
            }
          ],
          "name": "ApprovalForAll",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "getApproved",
          "outputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            }
          ],
          "name": "isApprovedForAll",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256[]",
              "name": "tokenIDs",
              "type": "uint256[]"
            }
          ],
          "name": "ownerAndLastTransferBlockNumberList",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "lastTransferBlockNumber",
                  "type": "uint256"
                }
              ],
              "internalType": "struct IERC721WithBlocknumber.OwnerData[]",
              "name": "ownersData",
              "type": "tuple[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "ownerAndLastTransferBlockNumberOf",
          "outputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "blockNumber",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "ownerOf",
          "outputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "safeTransferFrom",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "internalType": "bool",
              "name": "approved",
              "type": "bool"
            }
          ],
          "name": "setApprovalForAll",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes4",
              "name": "interfaceID",
              "type": "bytes4"
            }
          ],
          "name": "supportsInterface",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "tokenURI",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "InvalidCellOverwrite",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "life",
              "type": "uint256"
            },
            {
              "internalType": "int32",
              "name": "x",
              "type": "int32"
            },
            {
              "internalType": "int32",
              "name": "y",
              "type": "int32"
            }
          ],
          "name": "InvalidLifeConfiguration",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct IStratagemsDebug.DebugCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "ForceCells",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct IStratagemsDebug.SimpleCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "ForceSimpleCells",
          "type": "event"
        },
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                }
              ],
              "internalType": "struct IStratagemsDebug.DebugCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "forceCells",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            }
          ],
          "name": "forceMoves",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                }
              ],
              "internalType": "struct IStratagemsDebug.SimpleCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "forceSimpleCells",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            }
          ],
          "name": "getRawCell",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Cell",
              "name": "",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ],
      "address": "0x03fef779885e53967c387d5df0678337ca4b536c",
      "startBlock": 4794079
    },
    "Stratagems_Implementation_Router": {
      "abi": [
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "address[]",
                  "name": "implementations",
                  "type": "address[]"
                },
                {
                  "internalType": "bytes5[]",
                  "name": "sigMap",
                  "type": "bytes5[]"
                },
                {
                  "internalType": "address",
                  "name": "fallbackImplementation",
                  "type": "address"
                }
              ],
              "internalType": "struct Router10X60.Routes",
              "name": "routes",
              "type": "tuple"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "stateMutability": "payable",
          "type": "fallback"
        }
      ],
      "address": "0x03fef779885e53967c387d5df0678337ca4b536c",
      "startBlock": 4794079
    },
    "Stratagems_Implementation_Router_Debug_Route": {
      "abi": [
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "contract IERC20WithIERC2612",
                  "name": "tokens",
                  "type": "address"
                },
                {
                  "internalType": "address payable",
                  "name": "burnAddress",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "startTime",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "commitPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "revealPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "maxLife",
                  "type": "uint8"
                },
                {
                  "internalType": "uint256",
                  "name": "numTokensPerGems",
                  "type": "uint256"
                },
                {
                  "internalType": "contract ITime",
                  "name": "time",
                  "type": "address"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Config",
              "name": "config",
              "type": "tuple"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [],
          "name": "CanStillReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "CommitmentHashNotMatching",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "GameNotStarted",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InCommitmentPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InRevealPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidCellOverwrite",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidEpoch",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidFurtherMoves",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "life",
              "type": "uint256"
            },
            {
              "internalType": "int32",
              "name": "x",
              "type": "int32"
            },
            {
              "internalType": "int32",
              "name": "y",
              "type": "int32"
            }
          ],
          "name": "InvalidLifeConfiguration",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NotAuthorized",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NothingToReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "PreviousCommitmentNotRevealed",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "inReserve",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "ReserveTooLow",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            }
          ],
          "name": "CommitmentCancelled",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentMade",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": true,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newReserveAmount",
              "type": "uint256"
            }
          ],
          "name": "CommitmentRevealed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountBurnt",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentVoid",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct IStratagemsDebug.DebugCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "ForceCells",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct IStratagemsDebug.SimpleCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "ForceSimpleCells",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "oldColor",
              "type": "uint8"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "newColor",
              "type": "uint8"
            }
          ],
          "name": "MoveProcessed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountDeposited",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveDeposited",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountWithdrawn",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveWithdrawn",
          "type": "event"
        },
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                }
              ],
              "internalType": "struct IStratagemsDebug.DebugCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "forceCells",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            }
          ],
          "name": "forceMoves",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                }
              ],
              "internalType": "struct IStratagemsDebug.SimpleCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "name": "forceSimpleCells",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            }
          ],
          "name": "getRawCell",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Cell",
              "name": "",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ],
      "address": "0x8dd92c9554937410c8a81cf3a2df011b6d1b1f55",
      "startBlock": 4794078
    },
    "Stratagems_Implementation_Router_ERC721_Route": {
      "abi": [
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "contract IERC20WithIERC2612",
                  "name": "tokens",
                  "type": "address"
                },
                {
                  "internalType": "address payable",
                  "name": "burnAddress",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "startTime",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "commitPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "revealPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "maxLife",
                  "type": "uint8"
                },
                {
                  "internalType": "uint256",
                  "name": "numTokensPerGems",
                  "type": "uint256"
                },
                {
                  "internalType": "contract ITime",
                  "name": "time",
                  "type": "address"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Config",
              "name": "config",
              "type": "tuple"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [],
          "name": "CanStillReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "CommitmentHashNotMatching",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "GameNotStarted",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InCommitmentPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InRevealPhase",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "addr",
              "type": "address"
            }
          ],
          "name": "InvalidAddress",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidEpoch",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidFurtherMoves",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "NonExistentToken",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NonceOverflow",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NotAuthorized",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "provided",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "currentOwner",
              "type": "address"
            }
          ],
          "name": "NotOwner",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NothingToReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "PreviousCommitmentNotRevealed",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "inReserve",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "ReserveTooLow",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "TransferRejected",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "approved",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "bool",
              "name": "approved",
              "type": "bool"
            }
          ],
          "name": "ApprovalForAll",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            }
          ],
          "name": "CommitmentCancelled",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentMade",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": true,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newReserveAmount",
              "type": "uint256"
            }
          ],
          "name": "CommitmentRevealed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountBurnt",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentVoid",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "oldColor",
              "type": "uint8"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "newColor",
              "type": "uint8"
            }
          ],
          "name": "MoveProcessed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountDeposited",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveDeposited",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountWithdrawn",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveWithdrawn",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "getApproved",
          "outputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            }
          ],
          "name": "isApprovedForAll",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256[]",
              "name": "tokenIDs",
              "type": "uint256[]"
            }
          ],
          "name": "ownerAndLastTransferBlockNumberList",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "lastTransferBlockNumber",
                  "type": "uint256"
                }
              ],
              "internalType": "struct IERC721WithBlocknumber.OwnerData[]",
              "name": "ownersData",
              "type": "tuple[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "ownerAndLastTransferBlockNumberOf",
          "outputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "blockNumber",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "ownerOf",
          "outputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "safeTransferFrom",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "safeTransferFrom",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "operator",
              "type": "address"
            },
            {
              "internalType": "bool",
              "name": "approved",
              "type": "bool"
            }
          ],
          "name": "setApprovalForAll",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes4",
              "name": "interfaceID",
              "type": "bytes4"
            }
          ],
          "name": "supportsInterface",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "tokenURI",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "tokenID",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        }
      ],
      "address": "0xdbbf9e502f930ac16f6c0ef9e9b2b7af4652564d",
      "startBlock": 4794077
    },
    "Stratagems_Implementation_Router_Getters_Route": {
      "abi": [
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "contract IERC20WithIERC2612",
                  "name": "tokens",
                  "type": "address"
                },
                {
                  "internalType": "address payable",
                  "name": "burnAddress",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "startTime",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "commitPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "revealPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "maxLife",
                  "type": "uint8"
                },
                {
                  "internalType": "uint256",
                  "name": "numTokensPerGems",
                  "type": "uint256"
                },
                {
                  "internalType": "contract ITime",
                  "name": "time",
                  "type": "address"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Config",
              "name": "config",
              "type": "tuple"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [],
          "name": "CanStillReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "CommitmentHashNotMatching",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "GameNotStarted",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InCommitmentPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InRevealPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidEpoch",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidFurtherMoves",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NothingToReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "PreviousCommitmentNotRevealed",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "inReserve",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "ReserveTooLow",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            }
          ],
          "name": "CommitmentCancelled",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentMade",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": true,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newReserveAmount",
              "type": "uint256"
            }
          ],
          "name": "CommitmentRevealed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountBurnt",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentVoid",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "oldColor",
              "type": "uint8"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "newColor",
              "type": "uint8"
            }
          ],
          "name": "MoveProcessed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountDeposited",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveDeposited",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountWithdrawn",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveWithdrawn",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "id",
              "type": "uint256"
            }
          ],
          "name": "getCell",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.FullCell",
              "name": "",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256[]",
              "name": "ids",
              "type": "uint256[]"
            }
          ],
          "name": "getCells",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "address",
                  "name": "owner",
                  "type": "address"
                },
                {
                  "internalType": "uint24",
                  "name": "lastEpochUpdate",
                  "type": "uint24"
                },
                {
                  "internalType": "uint24",
                  "name": "epochWhenTokenIsAdded",
                  "type": "uint24"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "life",
                  "type": "uint8"
                },
                {
                  "internalType": "int8",
                  "name": "delta",
                  "type": "int8"
                },
                {
                  "internalType": "uint8",
                  "name": "enemyMap",
                  "type": "uint8"
                },
                {
                  "internalType": "uint8",
                  "name": "distribution",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.FullCell[]",
              "name": "cells",
              "type": "tuple[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "getCommitment",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "bytes24",
                  "name": "hash",
                  "type": "bytes24"
                },
                {
                  "internalType": "uint24",
                  "name": "epoch",
                  "type": "uint24"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Commitment",
              "name": "commitment",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "getConfig",
          "outputs": [
            {
              "components": [
                {
                  "internalType": "contract IERC20WithIERC2612",
                  "name": "tokens",
                  "type": "address"
                },
                {
                  "internalType": "address payable",
                  "name": "burnAddress",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "startTime",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "commitPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "revealPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "maxLife",
                  "type": "uint8"
                },
                {
                  "internalType": "uint256",
                  "name": "numTokensPerGems",
                  "type": "uint256"
                },
                {
                  "internalType": "contract ITime",
                  "name": "time",
                  "type": "address"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Config",
              "name": "config",
              "type": "tuple"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "getTokensInReserve",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ],
      "address": "0xb90fc51c48d361550e4adf38d93337029ff0b1a4",
      "startBlock": 4794075
    },
    "Stratagems_Implementation_Router_Setters_Route": {
      "abi": [
        {
          "inputs": [
            {
              "components": [
                {
                  "internalType": "contract IERC20WithIERC2612",
                  "name": "tokens",
                  "type": "address"
                },
                {
                  "internalType": "address payable",
                  "name": "burnAddress",
                  "type": "address"
                },
                {
                  "internalType": "uint256",
                  "name": "startTime",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "commitPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "revealPhaseDuration",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "maxLife",
                  "type": "uint8"
                },
                {
                  "internalType": "uint256",
                  "name": "numTokensPerGems",
                  "type": "uint256"
                },
                {
                  "internalType": "contract ITime",
                  "name": "time",
                  "type": "address"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Config",
              "name": "config",
              "type": "tuple"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [],
          "name": "CanStillReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "CommitmentHashNotMatching",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "GameNotStarted",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InCommitmentPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InRevealPhase",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidEpoch",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidFurtherMoves",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NothingToReveal",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "PreviousCommitmentNotRevealed",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "inReserve",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "ReserveTooLow",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            }
          ],
          "name": "CommitmentCancelled",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentMade",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": true,
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "indexed": false,
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newReserveAmount",
              "type": "uint256"
            }
          ],
          "name": "CommitmentRevealed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "uint24",
              "name": "epoch",
              "type": "uint24"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountBurnt",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "CommitmentVoid",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "oldColor",
              "type": "uint8"
            },
            {
              "indexed": false,
              "internalType": "enum UsingStratagemsTypes.Color",
              "name": "newColor",
              "type": "uint8"
            }
          ],
          "name": "MoveProcessed",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountDeposited",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveDeposited",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "amountWithdrawn",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newAmount",
              "type": "uint256"
            }
          ],
          "name": "ReserveWithdrawn",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "secret",
              "type": "bytes32"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            }
          ],
          "name": "acknowledgeMissedReveal",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "acknowledgeMissedRevealByBurningAllReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "tokensAmountToAdd",
              "type": "uint256"
            },
            {
              "components": [
                {
                  "internalType": "uint256",
                  "name": "value",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "deadline",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "v",
                  "type": "uint8"
                },
                {
                  "internalType": "bytes32",
                  "name": "r",
                  "type": "bytes32"
                },
                {
                  "internalType": "bytes32",
                  "name": "s",
                  "type": "bytes32"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Permit",
              "name": "permit",
              "type": "tuple"
            }
          ],
          "name": "addToReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "cancelCommitment",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "makeCommitment",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes24",
              "name": "commitmentHash",
              "type": "bytes24"
            },
            {
              "internalType": "uint256",
              "name": "tokensAmountToAdd",
              "type": "uint256"
            },
            {
              "components": [
                {
                  "internalType": "uint256",
                  "name": "value",
                  "type": "uint256"
                },
                {
                  "internalType": "uint256",
                  "name": "deadline",
                  "type": "uint256"
                },
                {
                  "internalType": "uint8",
                  "name": "v",
                  "type": "uint8"
                },
                {
                  "internalType": "bytes32",
                  "name": "r",
                  "type": "bytes32"
                },
                {
                  "internalType": "bytes32",
                  "name": "s",
                  "type": "bytes32"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Permit",
              "name": "permit",
              "type": "tuple"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "makeCommitmentWithExtraReserve",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint64",
              "name": "position",
              "type": "uint64"
            }
          ],
          "name": "poke",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint64[]",
              "name": "positions",
              "type": "uint64[]"
            }
          ],
          "name": "pokeMultiple",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "player",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "secret",
              "type": "bytes32"
            },
            {
              "components": [
                {
                  "internalType": "uint64",
                  "name": "position",
                  "type": "uint64"
                },
                {
                  "internalType": "enum UsingStratagemsTypes.Color",
                  "name": "color",
                  "type": "uint8"
                }
              ],
              "internalType": "struct UsingStratagemsTypes.Move[]",
              "name": "moves",
              "type": "tuple[]"
            },
            {
              "internalType": "bytes24",
              "name": "furtherMoves",
              "type": "bytes24"
            },
            {
              "internalType": "bool",
              "name": "useReserve",
              "type": "bool"
            },
            {
              "internalType": "address payable",
              "name": "payee",
              "type": "address"
            }
          ],
          "name": "reveal",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "withdrawFromReserve",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        }
      ],
      "address": "0x0545021fd86958f412f016fe2fa395156ffee235",
      "startBlock": 4794076
    },
    "Stratagems_Proxy": {
      "abi": [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "implementationAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "ownerAddress",
              "type": "address"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "stateMutability": "payable",
          "type": "constructor"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "previousOwner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "OwnershipTransferred",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "previousImplementation",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newImplementation",
              "type": "address"
            }
          ],
          "name": "ProxyImplementationUpdated",
          "type": "event"
        },
        {
          "stateMutability": "payable",
          "type": "fallback"
        },
        {
          "inputs": [],
          "name": "owner",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes4",
              "name": "id",
              "type": "bytes4"
            }
          ],
          "name": "supportsInterface",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "transferOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newImplementation",
              "type": "address"
            }
          ],
          "name": "upgradeTo",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newImplementation",
              "type": "address"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "upgradeToAndCall",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "stateMutability": "payable",
          "type": "receive"
        }
      ],
      "address": "0x82404f6cf06afa60e472a611224c016469f797ec",
      "startBlock": 4794080
    },
    "TestTokens": {
      "abi": [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "components": [
                {
                  "internalType": "uint256",
                  "name": "fee",
                  "type": "uint256"
                },
                {
                  "internalType": "address payable",
                  "name": "feeReceiver",
                  "type": "address"
                }
              ],
              "internalType": "struct TestTokens.Config",
              "name": "config",
              "type": "tuple"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentTime",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadline",
              "type": "uint256"
            }
          ],
          "name": "DeadlineOver",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "addr",
              "type": "address"
            }
          ],
          "name": "InvalidAddress",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "provided",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "InvalidMsgValue",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "InvalidSignature",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "provided",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "InvalidTotalAmount",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentAllowance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "NotAuthorizedAllowance",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "currentBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expected",
              "type": "uint256"
            }
          ],
          "name": "NotEnoughTokens",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "WrongFee",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "DOMAIN_SEPARATOR",
          "outputs": [
            {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            }
          ],
          "name": "allowance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "approveAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "decimals",
          "outputs": [
            {
              "internalType": "uint8",
              "name": "",
              "type": "uint8"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address payable[]",
              "name": "tos",
              "type": "address[]"
            },
            {
              "internalType": "uint256",
              "name": "totalAmount",
              "type": "uint256"
            }
          ],
          "name": "distributeAlongWithETH",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "eip712Domain",
          "outputs": [
            {
              "internalType": "bytes1",
              "name": "fields",
              "type": "bytes1"
            },
            {
              "internalType": "string",
              "name": "name",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "version",
              "type": "string"
            },
            {
              "internalType": "uint256",
              "name": "chainID",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "verifyingContract",
              "type": "address"
            },
            {
              "internalType": "bytes32",
              "name": "salt",
              "type": "bytes32"
            },
            {
              "internalType": "uint256[]",
              "name": "extensions",
              "type": "uint256[]"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "fee",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "feeReceiver",
          "outputs": [
            {
              "internalType": "address payable",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "pure",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "name": "nonces",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "deadline",
              "type": "uint256"
            },
            {
              "internalType": "uint8",
              "name": "v",
              "type": "uint8"
            },
            {
              "internalType": "bytes32",
              "name": "r",
              "type": "bytes32"
            },
            {
              "internalType": "bytes32",
              "name": "s",
              "type": "bytes32"
            }
          ],
          "name": "permit",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "topup",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "totalSupply",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transfer",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address payable",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transferAlongWithETH",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "payable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferFromAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "forAddress",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
            }
          ],
          "name": "transferOnBehalfAndCall",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        }
      ],
      "address": "0xa3573742b86d1580b710653efcf03a405fe80f7d",
      "startBlock": 4794072
    },
    "Time": {
      "abi": [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [],
          "name": "NotAuthorized",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "previousOwner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "OwnershipTransferred",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "newTime",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "delta",
              "type": "uint256"
            }
          ],
          "name": "TimeIncreased",
          "type": "event"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "delta",
              "type": "uint256"
            }
          ],
          "name": "increaseTime",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "owner",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "timestamp",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "transferOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "contract IERC20",
              "name": "token",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            }
          ],
          "name": "withdrawERC20",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        }
      ],
      "address": "0x52092c12759ac66d997d402c07d5449b3f2136f8"
    }
  },
  "name": "sepolia"
} as const;